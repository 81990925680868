import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '~layouts/layout';
import { RenderedContext } from '~layouts/layout';
import Header from '~2-components/Header/Header';
import RichText from '~3-data-components/RichText';
import useSiteMetadata from '~3-data-components/useSiteMetaData';
import Helmet from 'react-helmet';
import redirectTo from '~/utils/redirect';

export const query = graphql`
    query($id: String!) {
        kontentItemGeneralContent(id: { eq: $id }) {
            system {
                id
                type
                name
            }
            elements {
                title {
                    value
                }
                page_slug {
                    value
                }
                introduction {
                    value
                }
                page_metadata__share_description {
                    value
                }
                page_metadata__share_image {
                    value {
                        url
                    }
                }
                page_metadata__share_title {
                    value
                }
                page_metadata__twitter_creator {
                    value
                }
                client_side_redirection__redirect_url {
                    value
                }
                hero_image {
                    value {
                        url
                        description
                        name
                        fluid(maxWidth: 1440, maxHeight: 800) {
                            ...KontentAssetFluid_withWebp
                        }
                    }
                }
                mobile_image {
                    value {
                        url
                        description
                        name
                        fluid(maxWidth: 1440, maxHeight: 800) {
                            ...KontentAssetFluid_withWebp
                        }
                    }
                }
                mobile_image_alignment {
                    value {
                        codename
                    }
                }
                introduction_cta_text {
                    value
                }
                introduction_cta_url {
                    value
                }
                secondary_intro_cta_url {
                    value
                }
                secondary_intro_cta_text {
                    value
                }
                media_query_contact {
                    value {
                        system {
                            type
                            id
                        }
                    }
                }
                include_contact_number {
                    value {
                        codename
                    }
                }
                hide_footer_banner {
                    value {
                        codename
                    }
                }
                parent_page {
                    value {
                        ... on kontent_item_general_content {
                            elements {
                                page_slug {
                                    value
                                }
                                title {
                                    value
                                }
                            }
                        }
                    }
                }
                rich_text {
                    modular_content {
                        system {
                            id
                            type
                            codename
                        }
                    }
                    images {
                        description
                        image_id
                        width
                        height
                        fluid(maxWidth: 996) {
                            ...KontentAssetFluid_withWebp
                        }
                    }
                    links {
                        codename
                        link_id
                        type
                        url_slug
                    }
                    value
                }
            }
        }
        allKontentItemSiteSettings {
            nodes {
                elements {
                    homepage {
                        value {
                            ... on kontent_item_general_content {
                                elements {
                                    page_slug {
                                        value
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

function generateBreadcrumbs(currentPage, currentSlug, parentPage, homepageSlug) {
    const breadcrumbs = [{
        title: 'Home',
        pageSlug: homepageSlug,
    }];

    if (parentPage) {
        breadcrumbs.push({
            title: parentPage.title.value,
            pageSlug: parentPage.page_slug.value,
        });
    }

    if (currentSlug === homepageSlug) {
        breadcrumbs[0].isActive = true;
        return breadcrumbs;
    }

    breadcrumbs.push({
        title: currentPage,
        pageSlug: currentSlug,
        isActive: true,
    });

    return breadcrumbs;
}

const GeneralContentTemplate = ({ data, location }) => {
    const redirectUrl = data.kontentItemGeneralContent.elements.client_side_redirection__redirect_url.value;
    redirectTo(redirectUrl);

    const pageData = data.kontentItemGeneralContent.elements;
    const richTextContent = pageData.rich_text;
    const pageInfo = data.kontentItemGeneralContent.system;
    const parentPage = pageData.parent_page.value.length ? pageData.parent_page.value[0].elements : false;
    const pageTitle = pageData.title.value;
    const pageSlug = pageData.page_slug.value;

    const hasImage = pageData.hero_image.value.length > 0 ? true : false;
    const heroImage = hasImage ? pageData.hero_image.value[0] : null;
    const mobileImage = pageData.mobile_image.value.length > 0 ? pageData.mobile_image.value[0] : null;
    const mobileImageAlignment = pageData.mobile_image_alignment.value.length > 0 ? pageData.mobile_image_alignment.value[0].codename : 'right';

    const homepageSlug = data.allKontentItemSiteSettings.nodes[0].elements.homepage.value.length > 0 ? data.allKontentItemSiteSettings.nodes[0].elements.homepage.value[0].elements.page_slug.value : '<p>PLease add page slug value</p>';
    const breadcrumbs = generateBreadcrumbs(pageTitle, pageSlug, parentPage, homepageSlug);
    const hideFooterCTA = pageData.hide_footer_banner.value.length ? true : false;

    const { site_title, site_description, site_url, default_share_image, twitter_site } = useSiteMetadata();

    const customMetaDescription = pageData.page_metadata__share_description.value;
    const customMetaImage = pageData.page_metadata__share_image.value;
    const customMetaTitle = pageData.page_metadata__share_title.value;
    const metaDataTwitterCreator = pageData.page_metadata__twitter_creator.value.length ? pageData.page_metadata__twitter_creator.value : twitter_site.value;
    const pagePath = `${site_url.value}${location.pathname}`;
    const defaultShareImage = default_share_image.length > 0 ? default_share_image.value[0].url : '';
    const metaDataImage = customMetaImage.length ? customMetaImage[0].url : defaultShareImage;
    const metaDataTitle = customMetaTitle.length ? customMetaTitle : pageTitle;
    const metaDataDescription = customMetaDescription.length ? customMetaDescription : site_description.value;
    // const addSeoSchema = (pageData.add_faq_schema.value[0] && pageData.add_faq_schema.value[0].codename) === "true";

    const [setBanner] = useState({
        breadcrumbs: breadcrumbs,
        title: pageTitle,
        introduction: pageData.introduction,
        hasImage: hasImage,
        heroImage: heroImage,
        mobileImage: mobileImage,
        mobileImageAlignment: mobileImageAlignment,
        cta1: {
            txt: pageData.introduction_cta_text.value,
            url: pageData.introduction_cta_url.value
        },
        cta2: {
            txt: pageData.secondary_intro_cta_text.value,
            url: pageData.secondary_intro_cta_url.value
        },
        mediaQuery: pageData.media_query_contact.value.length > 0 ? pageData.media_query_contact.value[0].system.id : '',
        showContact: pageData.include_contact_number.value.length > 0 ? pageData.include_contact_number.value[0].codename : false
    });

    // let pageContentProps = {};

    // if (addSeoSchema) {
    //     pageContentProps = {
    //         itemScope: true,
    //         itemType: "https://schema.org/FAQPage"
    //     }
    // }

    return (
        <Layout
            hideFeatureCTA={hideFooterCTA}
        >
            <RenderedContext.Provider value={setBanner}>
                <Header />
            </RenderedContext.Provider>

            <Helmet
                title={`${metaDataTitle} | ${site_title.value}`}
            >
                <meta name="description" content={metaDataDescription} />
                <meta property="og:type" content='website' />
                <meta property="og:site_name" content={site_title.value} />
                <meta property="og:url" content={pagePath} />
                <meta property="og:title" content={metaDataTitle} />
                <meta property="og:description" content={metaDataDescription} />
                <meta property="og:image" content={metaDataImage} />
                <meta name="twitter:card" value='summary' />
                <meta name="twitter:site" content={twitter_site.value} />
                <meta name="twitter:creator" content={metaDataTwitterCreator} />
                <meta name="twitter:url" content={pagePath} />
                <meta name="twitter:title" content={metaDataTitle} />
                <meta name="twitter:description" content={metaDataDescription} />
                <meta name="twitter:image" content={metaDataImage} />
                <link rel="canonical" href={pagePath} />
            </Helmet>

            <div className="page-content">
                <div className="container">
                    <RichText
                        content={richTextContent}
                        images={richTextContent.images}
                        links={richTextContent.links}
                        linkedItems={richTextContent.modular_content}
                        pageInfo={pageInfo}
                    />
                </div>
            </div>
        </Layout>
    );
};

export default GeneralContentTemplate;

GeneralContentTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};

